<template>
	<div class="bg-white p-4 rounded border shadow-sm h-100">
		<b-overlay :show="loading" variant="light" spinner-type="grow">

			<section class="rounded bg-light-app px-4 pt-4 pb-2 mb-4 position-relative">
				<h1 class="display-4">
					Students
					<small class="text-muted">Manage your club students</small>
				</h1>
				<b-button variant="primary"
				          @click="add"
				          class="position-absolute"
				          style="right: 1rem; top: 1rem;">
					<b-icon icon="plus" font-scale="2"></b-icon>
				</b-button>
			</section>

			<div class="page-inset shadow">

				<error-alert :error="error" class="m-4"/>

				<splitpanes class="default-theme" :push-other-panes="false">

					<pane size="60" min-size="30" max-size="90">
						<b-navbar type="dark" variant="dark" class="p-1">

							<b-nav-text class="ml-2">{{ bvDataTablePaginationInfo }}</b-nav-text>

							<b-navbar-nav class="ml-auto mr-2">

								<b-nav-item-dropdown right no-caret>
									<template #button-content>
										<b-icon icon="download" aria-hidden="true"></b-icon>
									</template>
									<b-dropdown-item @click="bvDataTableDownloadAll('json')">Download all students
									                                                         (JSON)
									</b-dropdown-item>
									<b-dropdown-item @click="bvDataTableDownloadAll('csv')">Download all students (CSV)
									</b-dropdown-item>
								</b-nav-item-dropdown>

							</b-navbar-nav>

						</b-navbar>

						<div class="d-lg-flex flex-lg-row align-content-center justify-content-between my-3">

							<div>
								<b-input-group class="pr-4 pr-lg-0 ml-lg-2">

									<template #prepend>
										<b-button :disabled="!bvDataTable.textFilter" variant="faded" size="sm"
										          class="shadow-none"
										          @click="bvDataTable.textFilter = null">
											<b-icon v-if="!bvDataTable.textFilter" icon="search"
											        aria-hidden="true"></b-icon>
											<b-icon v-if="bvDataTable.textFilter" icon="x-circle-fill"
											        aria-hidden="true"></b-icon>
										</b-button>
									</template>

									<b-form-input
											v-model="bvDataTable.textFilter"
											size="md"
											placeholder="Search"
									></b-form-input>

								</b-input-group>
							</div>

							<div>
								<b-pagination
										v-model="bvDataTable.currentPage"
										:total-rows="bvDataTable.totalRecords"
										:per-page="bvDataTable.rowsPerPage"
										aria-controls="studentsTable"
										align="fill"
										class="m-4 m-lg-0"
										style="margin-top: 7px !important;"
								></b-pagination>
							</div>

							<div class="mb-4 mb-lg-0 pr-lg-4 text-center text-lg-right">

								<b-dropdown>
									<template #button-content>
										{{ bvDataTable.rowsPerPage }} rows per page
									</template>
									<b-dropdown-item
											v-for="rpp of bvDataTable.rowsPerPageOptions"
											:key="rpp"
											:active="bvDataTable.rowsPerPage === rpp"
											@click="bvDataTable.rowsPerPage = rpp"
									>{{ rpp }}
									</b-dropdown-item>
								</b-dropdown>

							</div>

						</div>

						<b-table
								striped
								hover
								responsive="true"
								:id="bvDataTable.id"
								:items="bvDataTableFetch"
								:busy.sync="bvDataTable.fetchingData"
								:fields="bvDataTable.headers"
								:primary-key="'id'"
								:per-page="bvDataTable.rowsPerPage"
								:current-page="bvDataTable.currentPage"
								:filter="bvDataTable.textFilter"
								selectable
								selected-variant="primary"
								select-mode="single"
								@row-selected="bvDataTableSelectRow"
						></b-table>
					</pane>

					<pane width="40%">

						<b-alert v-if="!bvDataTable.currentRecord" variant="info" show class="m-2 rounded shadow-sm">
							No {{ bvDataTable.resourceName }} selected.
						</b-alert>

						<b-navbar v-if="bvDataTable.currentRecord" type="dark" variant="dark" class="p-1"
						          style="height: 56px">

							<b-nav-text class="ml-2">{{ currentStudentName }}</b-nav-text>

						</b-navbar>

						<data-form-student
								v-if="bvDataTable.currentRecord"
								:record="bvDataTable.currentRecord"
								:class-locations="classLocations"
								:class-types="classTypes"
								@reset="bvDataTableRestoreCurrentRecord"
								@changed="bvDataTableUpdateCurrentRecord"
								@deleted="bvDataTableDeleteCurrentRecord"
						/>

					</pane>

				</splitpanes>

			</div>

		</b-overlay>
	</div>
</template>

<script>

import bvDataTableMixin from '@/mixins/bvDataTable.mixin';
import DataFormStudent  from '@/components/data-forms/Student';
import PapaCSV          from 'papaparse';

export default {

    name      : 'Students',
    mixins    : [ bvDataTableMixin ],
    components: { DataFormStudent },

    props: {},

    data () {
        return {

            bvDataTable: this.bvDataTableGetDataDefaults (
                {
                    id            : 'studentsTable',
                    collectionName: 'students',
                    resourceName  : 'student',
                    headers       : [
                        { key: 'identifier', label: 'Identifier', sortable: true },
                        { key: 'firstName', label: 'First name', sortable: true },
                        { key: 'lastName', label: 'Last name', sortable: true },
                        { key: 'email', label: 'Email', sortable: true },
                        { key: 'mobile', label: 'Mobile No.', sortable: true }
                    ]
                }
            ),

            loading: true,
            error  : false,

            classLocations: [],
            classTypes    : []

        };
    },

    computed: {

        currentStudentName () {

            if ( !this.bvDataTable.currentRecord ) {
                return null;
            }

            if ( !this.bvDataTable.currentRecord.id ) {
                return 'New student';
            }

            return `${ this.bvDataTable.currentRecord.firstName } ${ this.bvDataTable.currentRecord.lastName }`.trim ();

        }

    },

    methods: {

        // Format a single record for display
        bvDataTableFormatRow ( row ) {

            row.identifier = `CEMAA${ row.identifier }`;

            return row;

        },

        async add () {

            // Get next available identifier
            const identifier = await this.$store.dispatch ( 'student/nextIdentifier' );

            // Create a new blank record
            this.bvDataTable.currentRecord = {
                identifier: identifier,
                address   : {},
                classes   : []
            };
        },

        async bvDataTableDownloadAll ( format ) {

            this.bvDataTable.fetchingData = true;

            try {

                const { data } = await this.$http.get (
                    `${ this.config.apiRoot }/${ this.bvDataTable.collectionName }?offset=0&limit=0`,
                    {
                        responseType   : 'json',
                        withCredentials: false // CORS dev server
                    }
                );

                switch ( format ) {

                    case 'csv':

                        // Tidy the data up
                        const parsed = data.records.map ( x => {

                            return {
                                identifier             : `CEMAA${ x.identifier }`,
                                lastName               : x.lastName,
                                firstName              : x.firstName,
                                email                  : x.email,
                                mobile                 : x.mobile,
                                dateOfBirth            : ( x.dateOfBirth || '' ).split ( 'T' )[ 0 ],
                                address                : Object
                                    .keys ( x.address )
                                    .filter ( y => ( x.address[ y ] || '' ).length > 0 )
                                    .map ( y => x.address[ y ] )
                                    .join ( '. ' ),
                                standingOrderDayOfMonth: x.standingOrderDayOfMonth
                            };

                        } );

                        const buffer = PapaCSV.unparse (
                            parsed,
                            {
                                quotes    : true,
                                quoteChar : '"',
                                escapeChar: '"',
                                delimiter : ','
                            }
                        );

                        this.$utils.downloadDataFile (
                            buffer,
                            'text/csv',
                            `${ this.bvDataTable.collectionName }.${ ( new Date () ).toISOString () }.csv`
                        );

                        break;

                    default:
                        this.$utils.downloadJSON (
                            data.records,
                            `${ this.bvDataTable.collectionName }.${ ( new Date () ).toISOString () }.json`
                        );

                }

                this.bvDataTable.fetchingData = false;

            } catch ( e ) {

                // Bubble the exception
                this.error = this.$utils.normaliseError ( e );
                this.$utils.logger ( 'error', '[STUDENTS] Error fetching data:', e );
                this.bvDataTable.fetchingData = false;

            }

        }

    },

    async mounted () {

        // Get class locations
        let response = await this.$http.get (
            `${ this.config.apiRoot }/locations`,
            {
                responseType   : 'json',
                withCredentials: false // CORS dev server
            }
        );
        this.classLocations = response.data.records.map ( x => {
            return {
                value: x.id,
                text : x.name
            };
        } );

        // Get classes
        response = await this.$http.get (
            `${ this.config.apiRoot }/classes`,
            {
                responseType   : 'json',
                withCredentials: false // CORS dev server
            }
        );
        this.classTypes = response.data.records.map ( x => {
            return {
                value: x.id,
                text : x.name
            };
        } );

        this.loading = false;

    }
};

</script>

<style scoped lang="scss">

</style>
