<template>
	<b-overlay :show="loading" variant="light" spinner-type="grow">
		<b-form @submit.prevent="saveRecord" class="text-left m-2">

			<b-alert :show="!!error" variant="danger" v-html="error.message"></b-alert>

			<b-card no-body class="mb-2">
				<b-tabs card>
					<b-tab title="Profile" active>

						<b-form-group
								label="Student number:"
								label-for="profileIdentifier"
								description="This is the payment reference number used for payment matching. It is automatically generated and cannot be changed."
								class="mb-1 p-2 shadow-sm"
						>
							<b-form-input
									id="profileIdentifier"
									:value="`CEMAA${record.identifier}`"
									type="text"
									required
									readonly
							></b-form-input>
						</b-form-group>

						<b-form-group
								label="Name:"
								label-for="profileFirstName"
								class="mb-1 p-2 shadow-sm"
								:state="!bvDataForm.validationErrors.firstName"
						>
							<b-form-input
									id="profileFirstName"
									v-model="record.firstName"
									required
									:invalid-feedback="bvDataForm.validationErrors.firstName"
									placeholder="First name"
									class="side-by-side-2"
							></b-form-input>
							<b-form-input
									id="profileLastName"
									v-model="record.lastName"
									required
									:invalid-feedback="bvDataForm.validationErrors.lastName"
									placeholder="Last name"
									class="side-by-side-2"
							></b-form-input>
						</b-form-group>

						<b-form-group
								label="Email address:"
								label-for="profileEmail"
								class="mb-1 p-2 shadow-sm"
								:invalid-feedback="bvDataForm.validationErrors.email"
								:state="!bvDataForm.validationErrors.email"
						>
							<b-form-input
									id="profileEmail"
									v-model="record.email"
									type="email"
							></b-form-input>
						</b-form-group>

						<b-form-group
								label="Mobile No.:"
								label-for="profileMobile"
								:invalid-feedback="bvDataForm.validationErrors.mobile"
								:state="!bvDataForm.validationErrors.mobile"
								class="mb-1 p-2 shadow-sm"
						>
							<b-form-input
									id="profileMobile"
									v-model="record.mobile"
							></b-form-input>
						</b-form-group>

						<b-form-group
								label="Date of birth:"
								label-for="profileDob"
								:invalid-feedback="bvDataForm.validationErrors.dateOfBirth"
								:state="!bvDataForm.validationErrors.dateOfBirth"
								class="mb-1 p-2 shadow-sm"
						>
							<b-form-datepicker
									id="profileDob"
									v-model="record.dateOfBirth"
							></b-form-datepicker>
						</b-form-group>

					</b-tab>
					<b-tab title="Address">

						<b-form-group
								label="House name/number:"
								label-for="profileAddressHouseNameNum"
								class="mb-1 p-2 shadow-sm"
								:invalid-feedback="bvDataForm.validationErrors['address.houseNameNum']"
								:state="!bvDataForm.validationErrors['address.houseNameNum']"
						>
							<b-form-input
									id="profileAddressHouseNameNum"
									v-model="record.address.houseNameNum"
									type="text"
							></b-form-input>
						</b-form-group>

						<b-form-group
								label="Address:"
								label-for="profileAddressLine1"
								class="mb-1 p-2 shadow-sm"
						>
							<b-form-input
									id="profileAddressLine1"
									v-model="record.address.line1"
									type="text"
									placeholder="Line 1"
									class="side-by-side-2"
							></b-form-input>
							<b-form-input
									id="profileAddressLine2"
									v-model="record.address.line2"
									type="text"
									placeholder="Line 2"
									class="side-by-side-2"
							></b-form-input>
						</b-form-group>

						<b-form-group
								label="Town:"
								label-for="profileAddressTown"
								class="mb-1 p-2 shadow-sm"
								:invalid-feedback="bvDataForm.validationErrors['address.town']"
								:state="!bvDataForm.validationErrors['address.town']"
						>
							<b-form-input
									id="profileAddressTown"
									v-model="record.address.town"
									type="text"
							></b-form-input>
						</b-form-group>

						<b-form-group
								label="County:"
								label-for="profileAddressCounty"
								class="mb-1 p-2 shadow-sm"
								:invalid-feedback="bvDataForm.validationErrors['address.county']"
								:state="!bvDataForm.validationErrors['address.county']"
						>
							<b-form-input
									id="profileAddressCounty"
									v-model="record.address.county"
									type="text"
							></b-form-input>
						</b-form-group>

						<b-form-group
								label="Postcode:"
								label-for="profileAddressPostcode"
								class="mb-1 p-2 shadow-sm"
								:invalid-feedback="bvDataForm.validationErrors['address.postcode']"
								:state="!bvDataForm.validationErrors['address.postcode']"
						>
							<b-form-input
									id="profileAddressPostcode"
									v-model="record.address.postcode"
									type="text"
							></b-form-input>
						</b-form-group>

					</b-tab>
					<b-tab title="Subscriptions">

						<b-form-group
								label="Main class location:"
								label-for="profileMainClassLocation"
								class="mb-1 p-2 shadow-sm"
								:invalid-feedback="bvDataForm.validationErrors.mainClassLocationId"
								:state="!bvDataForm.validationErrors.mainClassLocationId"
						>
							<b-form-select
									id="profileMainClassLocation"
									v-model="record.mainClassLocationId"
									:options="classLocations"
							></b-form-select>
						</b-form-group>

						<b-card title="Classes" class="shadow-sm">
							<table class="table table-hover">
								<thead>
									<tr>
										<th>Name</th>
										<th>Monthly Fee</th>
										<th></th>
									</tr>
								</thead>
								<tbody v-if="classes.length === 0">
									<tr>
										<td colspan="3"><em>No classes assigned</em></td>
									</tr>
								</tbody>
								<tbody v-else>
									<tr v-for="(classType, classTypeIdx) in classes" :key="classType.id">
										<td>{{ classType.name }}</td>
										<td>
											<b-form-input
													v-model.number="record.classes[classTypeIdx].monthlyFee"
													type="number"
													step="0.01"
													class="p-1"
													style="height: auto"
											></b-form-input>
										</td>
										<td class="text-center">
											<b-link @click="deleteClass(classTypeIdx)">
												<b-icon icon="trash" variant="danger"></b-icon>
											</b-link>
										</td>
									</tr>
								</tbody>
								<tfoot>
									<tr>
										<td colspan="3">
											<b-input-group prepend="Add class:">
												<b-form-select
														v-model="newClassType"
														:options="availableClassTypes"
														:disabled="availableClassTypes.length === 0"
														@change="addClassType"
												></b-form-select>
											</b-input-group>
										</td>
									</tr>
								</tfoot>
							</table>
						</b-card>

					</b-tab>
					<b-tab title="Payments">

						<b-form-group
								label="Standing order day of month:"
								label-for="profileAddressSoDom"
								class="mb-1 p-2 shadow-sm"
								:invalid-feedback="bvDataForm.validationErrors.standingOrderDayOfMonth"
								:state="!bvDataForm.validationErrors.standingOrderDayOfMonth"
						>
							<b-form-input
									id="profileAddressSoDom"
									v-model="record.standingOrderDayOfMonth"
									type="number"
									min="1"
									max="31"
									step="1"
							></b-form-input>
						</b-form-group>

						<b-card title="Payment history" class="shadow-sm">
							<table class="table mt-4 table-hover">
								<thead>
									<tr>
										<th>Date</th>
										<th>Amount</th>
										<th>Type</th>
										<th>Method</th>
									</tr>
								</thead>
								<tbody>
									<tr
											v-for="payment of formattedPayments"
											:key="payment.id"
									>
										<td>{{ payment.date }}</td>
										<td>{{ payment.amount }}</td>
										<td>{{ payment.type }}</td>
										<td>{{ payment.method }}</td>
									</tr>
								</tbody>
							</table>
						</b-card>

					</b-tab>
				</b-tabs>
			</b-card>

			<div class="d-flex justify-content-end">
				<b-button v-if="!isNew" type="button" class="text-danger" variant="link"
				          @click="deleteRecord">
					Delete
				</b-button>
				<b-button type="button" :disabled="!isNew && !bvDataForm.dirty" class="ml-2 mr-0" variant="secondary"
				          @click="bvDataFormReset">
					{{ isNew ? 'Cancel' : 'Reset' }}
				</b-button>
				<b-button type="submit" :disabled="!bvDataForm.dirty" class="ml-2 mr-0" variant="primary">
					{{ isNew ? 'Create' : 'Save Changes' }}
				</b-button>
			</div>

		</b-form>
	</b-overlay>
</template>

<script>

import { cloneDeep, omit } from 'lodash-es';
import bvDataFormMixin     from '@/mixins/bvDataForm.mixin';
import { DateTime }        from 'luxon';

export default {

    name      : 'DataFormStudent',
    mixins    : [ bvDataFormMixin ],
    components: {},

    props: {

        record: {
            type: Object
        },

        classLocations: {
            type    : Array,
            required: true
        },

        classTypes: {
            type    : Array,
            required: true
        }

    },

    computed: {

        isNew () {
            return this.record && !this.record.id;
        },

        classes () {
            return this.record.classes.map ( x => {
                const classType = this.classTypes.find ( y => y.value === x.classId );
                return {
                    ...x,
                    name: classType.text
                };
            } );
        },

        availableClassTypes () {
            return this.classTypes.filter ( x => !this.record.classes.includes ( x.value ) );
        },

        formattedPayments () {
            return ( this.record.payments || [] ).map ( x => {

                return {
                    date  : DateTime
                        .fromISO ( x.paymentDate )
                        .toFormat ( 'dd/LL/yyyy' ),
                    amount: `£${ x.amount.toFixed ( 2 ) }`,
                    type  : x.transactionType.name,
                    method: x.paymentMethod.name
                };

            } );
        }

    },

    data () {
        return {
            bvDataForm  : this.bvDataFormGetDataDefaults (
                {
                    id          : 'studentsForm',
                    resourceName: 'student'
                }
            ),
            loading     : true,
            error       : false,
            newClassType: null
        };
    },

    watch: {},

    methods: {

        async saveRecord () {
            try {

                this.loading = true;
                this.error = false;

                const saveData = cloneDeep ( omit ( this.record, this.$utils.auditFields ) );

                if ( saveData.dateOfBirth && saveData.dateOfBirth.length > 0 ) {
                    saveData.dateOfBirth = saveData.dateOfBirth.split ( 'T' )[ 0 ];
                }

                // Save changes
                await this.bvDataFormSave ( saveData );
                this.$utils.logger ( 'info', '[AUTH] Changes saved to student profile.' );
                this.$bvToast.toast (
                    `Changes for ${ saveData.firstName } were saved successfully.`,
                    {
                        title        : 'Saved',
                        autoHideDelay: 3000,
                        toaster      : 'b-toaster-top-center',
                        solid        : true
                    }
                );

                this.loading = false;
                return true;

            } catch ( e ) {
                this.error = {
                    message: 'The form has errors, please review and try again.',
                    errors : e.errors
                };
                this.bvDataForm.validationErrors = this.$utils.parseFormValidationErrors ( this.error.errors );
                this.loading = false;
                return false;
            }
        },

        async deleteRecord () {
            try {

                this.loading = true;
                this.error = false;

                // Delete record
                const response = await this.bvDataFormDelete ();
                if ( response !== false ) {

                    this.$utils.logger ( 'info', '[AUTH] Student deleted.', response.data );
                    this.$bvToast.toast (
                        `Student ${ response.data.email } deleted successfully.`,
                        {
                            title        : 'Deleted',
                            autoHideDelay: 3000,
                            toaster      : 'b-toaster-top-center',
                            solid        : true
                        }
                    );

                }

                this.loading = false;
                return true;

            } catch ( e ) {
                this.error = {
                    message: 'The form has errors, please review and try again.',
                    errors : e.errors
                };
                this.bvDataForm.validationErrors = this.$utils.parseFormValidationErrors ( this.error.errors );
                this.loading = false;
                return false;
            }
        },

        deleteClass ( classTypeIdx ) {
            this.record.classes.splice ( classTypeIdx, 1 );
        },

        addClassType () {
            if ( this.newClassType ) {
                this.record.classes.push (
                    {
                        classId   : this.newClassType,
                        monthlyFee: 0
                    }
                );
                this.newClassType = null;
            }
        }

    },

    mounted () {

        // Ready
        this.loading = false;

    }

};

</script>

<style scoped lang="scss">
.side-by-side-2 {
	display: inline-block;
	width: calc(50% - 5px);
	margin-right: 5px;
}
</style>